import React from 'react';
import { GetServerSideProps, NextPage } from 'next';
import Head from 'next/head';
import { getSession } from 'next-auth/react';

import getPermissionsSSR from '@shared/ssrApiCalls/getPermissionsSSR';

import { getInternalMainAppHost } from '@packages/eh-utils/browserEnv';
import { fetchData } from '@shared/utils';

import {
  getJobMatchesSSR,
  getRecommendedJobsSSR,
} from 'src/modules/CareersPage/components/JobMatches/ssrApiCalls';
import UserProfileHome from 'src/modules/User/components/ProfileHome';
import { TUserProfile } from 'src/modules/User/types';
import UserDashboard from 'src/modules/UserDashboard';

import LandingPage from '../modules/CareersPage/components/LandingPage';

const LANDING_PAGE_TITLE =
  'Jobs Board: Find Thousands of Jobs Listed Worldwide';
const LANDING_PAGE_DESCRIPTION =
  "Swag's ATS jobs board lists thousands of jobs from companies worldwide hiring for remote, hybrid and onsite jobs. Try our ATS today!";
const LANDING_PAGE_META_IMAGE =
  'https://s3.ap-southeast-2.amazonaws.com/addons-assets.employmenthero.com/career-page/master/swag-job-banner.png';

export type THomePageProps = {
  authenticated: boolean;
  firstName?: string;
  profileStrengthScore?: number;
  smartMatchCount?: number;
  candidateDashboardEnhancements?: boolean;
};

const HomePage: NextPage<THomePageProps> = ({
  authenticated,
  firstName = '',
  profileStrengthScore,
  smartMatchCount,
  candidateDashboardEnhancements,
}) => {
  const UserProfileDashboard = candidateDashboardEnhancements ? (
    <UserDashboard firstName={firstName} />
  ) : (
    <UserProfileHome
      isLandingPage
      firstName={firstName}
      profileStrengthScore={profileStrengthScore}
      smartMatchCount={smartMatchCount}
    />
  );

  return (
    <>
      <Head>
        <title>{LANDING_PAGE_TITLE}</title>
        <meta name="description" content={LANDING_PAGE_DESCRIPTION} />
        {/* For Facebook and Open Graph */}
        <meta property="og:title" content={LANDING_PAGE_TITLE} />
        <meta property="og:description" content={LANDING_PAGE_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={'https://jobs.swagapp.com'} />
        <meta property="og:image" content={LANDING_PAGE_META_IMAGE} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        {/* For Twitter */}
        <meta name="twitter:title" content={LANDING_PAGE_TITLE} />
        <meta name="twitter:description" content={LANDING_PAGE_DESCRIPTION} />
        <meta name="twitter:image" content={LANDING_PAGE_META_IMAGE} />
        <meta name="twitter:card" content="summary_large_image" />
      </Head>
      {authenticated ? UserProfileDashboard : <LandingPage />}
    </>
  );
};

export const getServerSideProps: GetServerSideProps<
  THomePageProps
> = async context => {
  const { req } = context;
  const session = await getSession({ req });

  if (!session) {
    return {
      props: { authenticated: false },
    };
  }

  const responseUserProfileData = await fetchData<TUserProfile>(
    req,
    `${getInternalMainAppHost()}/api/v3/users/self_profile`
  );

  const permissions = await getPermissionsSSR(req);
  const permissionData = permissions?.data;
  const candidateDashboardEnhancements =
    permissionData?.candidate_dashboard_enhancement_enabled;
  const multiSearchEnabled =
    permissionData?.smart_match_multisearch_recommended_jobs_enabled;
  const responseUserProfile = responseUserProfileData.data;

  if (multiSearchEnabled) {
    const recommendJobResponse = await getRecommendedJobsSSR(req);

    return {
      props: {
        authenticated: true,
        firstName: responseUserProfile?.first_name ?? '',
        profileStrengthScore: responseUserProfile?.profile_strength_score ?? 0,
        smartMatchCount:
          recommendJobResponse.data?.career_page_jobs.length ?? 0,
        candidateDashboardEnhancements,
      },
    };
  }

  const recommendJobResponse = await getJobMatchesSSR(req);

  return {
    props: {
      authenticated: true,
      firstName: responseUserProfile?.first_name ?? '',
      profileStrengthScore: responseUserProfile?.profile_strength_score ?? 0,
      smartMatchCount: recommendJobResponse.data?.items.length ?? 0,
      candidateDashboardEnhancements,
    },
  };
};

export default HomePage;
