import { ParsedUrlQuery } from 'querystring';

import {
  compact,
  debounce,
  flow,
  isEmpty,
  map,
  toString,
  uniqBy,
} from 'lodash/fp';

import { useCallback, useEffect, useMemo } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { useRouter } from 'next/router';

import {
  appendInitOption,
  formatLocation,
  getLocationsByCountryCode,
} from '@shared/LocationField';
import { InitLocation } from '@shared/types';

import { TSearchFormProps } from 'src/modules/CareersPage/components/CandidateJobSearch/Header';
import useFetchAllCountries from 'src/modules/CareersPage/hooks/useFetchAllCountries';
import useFetchLocationName from 'src/modules/CareersPage/hooks/useFetchLocationName';
import { onFilterChangeSWR } from 'src/modules/CareersPage/utils/helpers';

import useMergeState from './useMergedState';

const getInitOption = (query: ParsedUrlQuery) => {
  if (query.vendorLocationId && query.vendorLocationName && query.countryCode) {
    return {
      value: Number(query.vendorLocationId as string),
      text: query.vendorLocationName as string,
      countryCode: query.countryCode as string,
    };
  }

  return null;
};

function useLocation({
  control,
  setValue,
}: {
  control: Control<TSearchFormProps, unknown>;
  setValue: UseFormSetValue<TSearchFormProps>;
}) {
  const router = useRouter();
  const { rawCountriesData, isLoadingCountries } = useFetchAllCountries();

  const countryCode = useWatch({
    control,
    name: 'countryCode',
    defaultValue: 'anywhere',
  });

  const vendorLocationId = useWatch({
    control,
    name: 'vendorLocationId',
  });

  const [locationQueryParams, setLocationQueryParams] = useMergeState({
    country_code: countryCode,
    query: '',
    location_count: 20,
  });
  const { rawLocationData, isLoadingLocationData } = useFetchLocationName({
    queryParams: locationQueryParams,
  });

  const initLocationOption = useMemo(
    () => getInitOption(router.query),
    [router.query]
  );

  const countryOptions = useMemo(
    () =>
      map(({ id, name }) => ({
        value: id,
        text: name,
      }))([
        { id: 'anywhere', name: 'Anywhere' },
        ...(rawCountriesData?.data?.items || []),
      ]),
    [rawCountriesData]
  );

  const locationOptions = useMemo(
    () =>
      flow(
        map(formatLocation),
        appendInitOption(initLocationOption),
        getLocationsByCountryCode(countryCode),
        uniqBy('value'),
        compact
      )(rawLocationData?.data?.items),
    [rawLocationData, countryCode, initLocationOption]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onLocationInputChange = useCallback(
    debounce(300)(input => {
      if (isEmpty(input) || input.trim().length < 3) return;

      onFilterChangeSWR({
        setQueryParams: setLocationQueryParams,
        queryParams: locationQueryParams,
      })({
        country_code: countryCode || 'AU',
        query: input,
      });
    }),
    [locationQueryParams, countryCode]
  );

  const updateLocationName = useCallback(
    (locationId: any) => {
      const location = locationOptions?.find(
        ({ value }: InitLocation) => toString(value) === toString(locationId)
      );

      setValue('vendorLocationName', location?.text);
    },
    [locationOptions, setValue]
  );

  useEffect(
    () => {
      if (vendorLocationId != null) {
        updateLocationName(vendorLocationId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vendorLocationId]
  );

  const countryName = countryOptions.find(
    ({ value }) => value === countryCode
  )?.text;

  return {
    disableLocation: countryCode === 'anywhere',
    updateLocationName,
    onLocationInputChange,
    locationOptions,
    countryOptions,
    isLoadingCountries,
    isLoadingLocationData,
    countryCode,
    countryName,
  };
}

export default useLocation;
