import React from 'react';
import styled from 'styled-components';

import { Box, Typography } from '@hero-design/react';

import { mediaMaxQueries } from '@packages/hero-theme/utils';
import useWindowSize from '@shared/hooks/useWindowSize';

import NotificationFeed from './components/NotificationFeed';

type UserDashboardProps = {
  firstName: string;
};

const Container = styled(Box)`
  width: 70%;

  ${mediaMaxQueries.xl} {
    width: 80%;
  }

  ${mediaMaxQueries.lg} {
    width: 100%;
  }
`;

const UserDashboard = ({ firstName }: UserDashboardProps) => {
  const { isSmallScreen } = useWindowSize();

  return (
    <Container
      pt={isSmallScreen ? 'medium' : 'xxlarge'}
      pb={isSmallScreen ? 'medium' : 'xxlarge'}
    >
      <Box p="medium">
        <Typography.Title
          level={1}
          sx={{ mb: 'xlarge' }}
        >{`Welcome, ${firstName}`}</Typography.Title>
        <NotificationFeed />
      </Box>
    </Container>
  );
};

export default UserDashboard;
