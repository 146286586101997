export const RadiusOptions = [
  { value: 5000, text: '5 km' },
  { value: 10000, text: '10 km' },
  { value: 25000, text: '25 km' },
  { value: 50000, text: '50 km' },
  { value: 100000, text: '100 km' },
  { value: 200000, text: '200 km' },
];

export const LOCALE_TO_COUNTRY: Record<string, string | undefined> = {
  AU: 'AU',
  NZ: 'NZ',
  GB: 'GB',
  MY: 'MY',
  SG: 'SI',
  anywhere: undefined,
};
